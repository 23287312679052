<template>
  <v-app-bar class="appBar" dense app elevation="0">
    <v-app-bar-nav-icon class="theme--light" @click="closeDrawer()">
      <v-icon>mdi-close</v-icon>
    </v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
export default {
  name: "BuildingsDetailAppBar",
  data: function () {
    return {
      param: 0,
    };
  },
  methods: {
    closeDrawer() {
      this.$router.push({
        name: "Buildings",
        params: { id: "1.0.1.17", nodeName: "lobby" },
      });
    },
  },
};
</script>
